<template>
  <div>
    <div class="text-h3 mb-10">
      Запись на прием
    </div>

    <booking-settings />

    <v-divider class="mt-8 mb-8"></v-divider>

    <div class="text-h5 mb-4">Поисковые подсказки</div>

    <div class="text-h6">Прием в клинике</div>
    <booking-hints-form :mode="MODE_OFFLINE" />

    <v-divider class="mt-8 mb-8"></v-divider>

    <div class="text-h6">Онлайн-консультации</div>
    <booking-hints-form :mode="MODE_ONLINE" class="mb-12" />
  </div>
</template>

<script>
import BookingSettings from '@/components/Booking/BookingSettings.vue'
import BookingHintsForm, { MODE_OFFLINE, MODE_ONLINE } from '@/components/Booking/BookingHintsForm.vue'

export default {
  components: {
    BookingSettings,
    BookingHintsForm,
  },
  data () {
    return {
      MODE_ONLINE,
      MODE_OFFLINE,
    }
  }
}
</script>
