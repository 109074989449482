<template>
  <crud-provider
    :service="bookingPopularService"
    :id="mode"
    v-slot="{ fetch, submit, model }"
  >
    <div>
      <div v-if="fetch.pending">loading...</div>
      <template v-else-if="model">
        <booking-hints :value="model.categories" @input="$event => model.categories = $event" />
        <v-btn @click="submit" color="primary" class="mt-8">Сохранить</v-btn>
     </template>
    </div>
  </crud-provider>
</template>

<script>
import bookingPopularService from '@/services/booking-popular'
import CrudProvider from '@/components/CrudProvider.vue'
import BookingHints from '@/components/Booking/BookingHints.vue'
export { MODE_OFFLINE, MODE_ONLINE } from '@/services/booking-popular'

export default {
  components: {
    BookingHints,
    CrudProvider,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      bookingPopularService,
    }
  }
}
</script>
