<script>
import { createRequest } from '@/utils/requestsFactory'
import isUndefined from 'lodash/isUndefined'
import isFunction from 'lodash/isFunction'

export default {
  props: {
    service: {
      type: Object,
      required: true
    },
    id: {
      type: String,
    },
  },
  data () {
    return {
      model: this.id ? null : this.service.createModel(),
      fetch: createRequest(async () => {
        this.model = await this.service.get(this.id)
      }),
      save: createRequest(() => this.submit()),
    }
  },
  async mounted () {
    if (this.id) {
      await this.fetch.run()
    }
  },
  methods: {
    serialize (payload) {
      if (!isUndefined(this.service.toDto) && isFunction(this.service.toDto)) {
        return this.service.toDto(payload)
      }
      return payload
    },
    async submit () {
      const payload = this.serialize(this.model)
      if (this.id) {
        await this.service.update(this.id, payload)
      } else {
        await this.service.create(payload)
      }
    },
    delete () {
      return this.service.remove(this.id)
    }
  },
  computed: {
    scope () {
      return {
        save: this.save,
        fetch: this.fetch,
        model: this.model,
        submit: this.submit.bind(this),
        delete: this.delete.bind(this),
      }
    }
  },
  render () {
    const defaultSlot = this.$scopedSlots.default
    return defaultSlot ? this.$scopedSlots.default(this.scope) : null
  }
}
</script>
