<template>
  <tenant-settings-provider v-slot="{ loading, settings, save, saving }">
    <div v-if="loading">
      loading...
    </div>
    <div v-else-if="settings">
      <v-row>
        <v-col cols="3">
          <v-checkbox
            v-model="settings.features.booking.inClinicEnabled"
            label="Запись в клиники"
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="settings.features.booking.onlineEnabled"
            label="Запись на онлайн-консультации"
          />
        </v-col>
      </v-row>
      <v-btn color="primary" @click="() => save(settings)" :disabled="saving">
        {{ saving ? 'Сохранение...' : 'Сохранить' }}
      </v-btn>
    </div>
  </tenant-settings-provider>
</template>

<script>
import TenantSettingsProvider from '@/components/Tenant/TenantSettingsProvider.vue'

export default {
  components: {
    TenantSettingsProvider
  }
}
</script>
