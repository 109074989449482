<template>
  <AutocompleteAsync
    v-bind="attrs"
    v-on="$listeners"
  />
</template>

<script>
import AutocompleteAsync from '../Cart/AutocompleteAsync.vue'
import api from '../../services/specialities'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        name: "speciality",
        itemText: 'name',
        itemValue: 'id',
        load: (...args) => this.load(...args)
      }
    }
  },
  methods: {
    load (params) {
      return api.getAll(params)
    },
  }
}
</script>
