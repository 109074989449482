<template>
  <form @submit.prevent="add">
    <booking-hint-select @input="add" />
    <v-chip-group>
      <vue-draggable v-model="hints" @input="onInput">
        <v-chip
          v-for="hint, idx in hints"
          :key="idx"
          close
          @click:close="() => remove(idx)"
        >
          {{ getHintText(hint) }}
        </v-chip>
      </vue-draggable>
    </v-chip-group>
  </form>
</template>

<script>
import VueDraggable from 'vuedraggable'
import isEqual from 'lodash/isEqual'
import BookingHintSelect from '@/components/Booking/BookingHintSelect.vue'

export default {
  components: {
    BookingHintSelect,
    VueDraggable,
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      hints: this.value || []
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(this.hints, value)) return
        this.hints = value
      }
    }
  },
  methods: {
    onInput () {
      this.$emit('input', this.hints)
    },
    add (hint) {
      this.hints.push(hint)
      this.onInput()
    },
    remove (idx) {
      this.$delete(this.hints, idx)
    },
    getHintText (hint) {
      return [
        hint.speciality?.name,
        hint.service?.name,
        Object.values(hint.searchValue || {}).filter((item) => item).join(' / ')
      ].filter((item) => item).join(' :: ')
    }
  }
}
</script>
