<template>
  <div>
    <v-radio-group row v-model="mode">
      <v-radio v-for="item, key in modes"
        :key="key"
        name="mode"
        :value="key"
        :label="item.label"
      />
    </v-radio-group>

    <spec-select
      v-if="mode === MODE_SPEC"
      :key="keys.speciality"
      outlined
      dense
      hide-details
      placeholder="Выберите специальность"
      @change="speciality => input({ speciality })"
    />

    <service-select
      v-if="mode === MODE_SERVICE"
      :key="keys.service"
      outlined
      dense
      hide-details
      placeholder="Выберите улсугу"
      @change="service => input({ service })"
    />

    <v-row v-if="mode === MODE_TEXT">
      <v-col>
        <locale-field
          component="v-text-field"
          v-model.trim="form.searchValue"
          placeholder="Введите текст"
          dense
          outlined
          name="text"
          hide-details
          :key="keys.searchValue"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          block
          style="height: 90px"
          color="success"
          :disabled="!Object.values(form.searchValue || {}).filter((item) => item).length"
          @keydown.enter="searchValue => input({ searchValue: form.searchValue })"
          @click="searchValue => input({ searchValue: form.searchValue })"
        >
          <v-icon left>mdi-plus</v-icon>
          Добавить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpecSelect from '@/components/Spec/SpecSelect.vue'
import ServiceSelect from '@/components/Service/ServiceSelect.vue'
import LocaleField from '@/components/Locale/LocaleField.vue'

export const MODE_SPEC = 'MODE_SPEC'
export const MODE_SERVICE = 'MODE_SERVICE'
export const MODE_TEXT = 'MODE_TEXT'

function Keys (initialValue = 1) {
  const state = new State()
  return Object
    .keys(state)
    .reduce((acc, key) => ({ ...acc, [key]: initialValue }), {})
}

function State (options) {
  const {
    speciality = null,
    service = null,
    searchValue = null,
  } = options || {}
  return {
    speciality,
    service,
    searchValue,
  }
}

export default {
  components: {
    SpecSelect,
    ServiceSelect,
    LocaleField,
  },
  data () {
    return {
      mode: MODE_SPEC,
      MODE_SERVICE,
      MODE_SPEC,
      MODE_TEXT,
      form: new State(),
      keys: new Keys(),
    }
  },
  computed: {
    modes () {
      return {
        [MODE_SPEC]: { label: 'Специальность' },
        [MODE_SERVICE]: { label: 'Услуга' },
        [MODE_TEXT]: { label: 'Текст' }
      }
    }
  },
  methods: {
    reset () {
      this.form = new State()
      Object.keys(this.keys).forEach((key) => this.keys[key]++)
    },
    input (payload) {
      this.$emit('input', payload)
      this.reset()
    },
  }
}
</script>
