import BaseService from './baseService'
import api from '../utils/api'
import clone from 'lodash/clone'

export const MODE_ONLINE = 'online'
export const MODE_OFFLINE = 'in-clinics'

const modes = { MODE_ONLINE, MODE_OFFLINE }

export function validateMode (mode) {
  const values = Object.values(modes)
  if (!values.includes(mode)) {
    throw Error(`Argument "mode" should be one of: ${values}. "${mode} passed"`)
  }
}

class BookingPopularService extends BaseService {

  get url () {
    return 'booking/popular-categories'
  }

  toDto (entity) {
    const dto = clone(entity)
    dto.categories = dto.categories.map(({ searchValue, speciality, service }) => ({
      specialityId: speciality?.id,
      serviceId: service?.id,
      searchValue
    }))
    return dto
  }

  get (mode) {
    validateMode(mode)
    return api.get(`${this.url}/${mode}`)
  }

  update (mode, { categories }) {
    validateMode(mode)
    return api.post(`${this.url}/${mode}`, { categories })
  }
}

export default new BookingPopularService()

